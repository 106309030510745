import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Select from "react-select";
import { AiOutlineClose } from "react-icons/ai";
import { BsWindowStack } from "react-icons/bs";
import { FaSave } from "react-icons/fa";
import { SiBookstack } from "react-icons/si";
import { GrDocument } from "react-icons/gr";
import { GiCrossMark } from "react-icons/gi";
import { AiFillPrinter } from "react-icons/ai";
import { TbWindowMaximize, TbWindowMinimize } from "react-icons/tb";
import AllPageNavbar from "../Components/AllPageNavbar";
import Loading from "../Components/Loading";
import { useDispatch, useSelector } from "react-redux";
const LocationCreation = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const BASEURL_GLOBAL = process.env.REACT_APP_BASEURL_GLOBAL;
  const userState = useSelector((state) => state.changeTheState.user);
  const LoggedInUserName = userState?.emailId?.split("@")[0].toUpperCase();
  const [search, setSearch] = useState("");
  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 0,
    2
  );
  const [fromDateFilter, setFromDateFilter] = useState(
    firstDayOfMonth?.toISOString()?.split("T")[0]
  );
  const [toDateFilter, setToDateFilter] = useState(
    currentDate?.toISOString()?.split("T")[0]
  );
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [materVehicleModel, setMasterVehicleModel] = useState([]);
  const [isloading, setisloading] = useState(true);
  const [mainCategory, setmainCategory] = useState([]);
  const [materVehicle, setMasterVehicle] = useState([]);
  const [categoryCode, setCategoryCode] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [wareHouseCode, setWareHouseCode] = useState("");
  const [gettinWarehouseCode, setGettinWarehouseCode] = useState([]);
  const [datePush, setDatePush] = useState(
    new Date()?.toISOString()?.slice(0, 10)
  );
  const [processedBy, setProcessedBy] = useState("");
  const [isMainCategory, setIsMainCategory] = useState(false);
  const [length, setLength] = useState("");
  const [breadth, setBreadth] = useState("");
  const [height, setHeight] = useState("");

  const handleLengthChange = (e) => {
    setLength(e.target.value);
  };

  const handleBreadthChange = (e) => {
    setBreadth(e.target.value);
  };

  const handleHeightChange = (e) => {
    setHeight(e.target.value);
  };

  const [mainCategoryCode, setMainCategoryCode] = useState("");
  const confirmSave = () => {
    return window.confirm("Are you sure you want to save the values?");
  };

  async function submitCategory(type) {
    // Check if required fields are filled
    if (
      !categoryCode ||
      !categoryName ||
      !wareHouseCode ||
      !datePush ||
      !LoggedInUserName ||
      !length ||
      !breadth ||
      !height
    ) {
      let missingFields = [];
      if (!categoryCode) missingFields.push("Location Code");
      if (!categoryName) missingFields.push("Location Name");
      if (!wareHouseCode) missingFields.push("ware House Code Name");
      if (!length) missingFields.push("length");
      if (!breadth) missingFields.push("breadth");
      if (!height) missingFields.push("height");

      if (!datePush) missingFields.push("Date");
      if (!LoggedInUserName) missingFields.push("Processed By");
      // if (!mainCategoryCode) missingFields.push("Main Category Code");

      alert(
        `Please fill in the following fields:\n${missingFields.join("\n")}`
      );
      return;
    }
    if (!confirmSave()) {
      return; // Don't proceed if user cancels
    }
    const datePassingFormat =
      datePush.slice(0, 4) +
      "/" +
      datePush.slice(5, 7) +
      "/" +
      datePush.slice(8, 10);
    try {
      // /insertLocation/${type}
      const response = await axios.post(
        `${BASEURL_GLOBAL}/globalSpHandler?spname=177&successstatus=true`,
        {
          LOCATIONCODE: categoryCode,
          LOCATIONNAME: categoryName,
          WAREHOUSECODE: wareHouseCode,
          LENGTH: length,
          BREADTH: breadth,
          HEIGHT: height,
          CREATEDDATE: datePassingFormat,
          CREATEDBY: LoggedInUserName,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );

      if (response.status === 201) {
        // alert(response.data.message);
        alert(`Location saved successfully`);
        window.location.reload();
      } else {
        alert(response?.data?.message);
        window.location.reload();
        console.error(`Error while saving insertLocation`);
      }
    } catch (error) {
      console.log(error);
      alert(error?.response?.data?.message);
      window.location.reload();
      console.error(`Error while saving insertLocation`, error);
    }
  }
  // getWarehouselist
  useEffect(() => {
    masterData();
  }, []);
  function masterData() {
    setisloading(true);
    // getLocation;
    axios
      .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=634`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setisloading(false);
        setMasterVehicle(response?.data);
      });
    setisloading(true);
    // getWarehouselist;
    axios
      .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=561`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setGettinWarehouseCode(response?.data || []); // Ensure response.data is an array or default to empty array
        setisloading(false);
      })
      .catch((error) => {
        console.error("Error fetching warehouse data:", error);
        setisloading(false);
      });
  }

  const handleFromDateFilterChange = (event) => {
    setFromDateFilter(event.target.value);
  };

  const handleToDateFilterChange = (event) => {
    setToDateFilter(event.target.value);
  };
  const filteredData = materVehicle?.filter((item) => {
    const searchData = search?.toLowerCase()?.split(" ")?.filter(Boolean);

    const textMatch =
      searchData?.length === 0 ||
      searchData.every((term) => {
        return [
          "OM_LOC_CODE",
          "OM_WAREHOUSE_CODE",
          "OM_LOC_UOM",
          "OM_LOC_NAME",
          "OM_LOC_HEIGHT",
          "OM_LOC_LENGTH",
          "OM_LOC_BREADTH",
          // "OM_LOC_COMP_CODE",
          // "OM_LOC_CREATED_BY",
          // "OM_LOC_CREATED_DATE",
        ].some((key) => {
          const columnValue = String(item[key])?.toLowerCase();
          return columnValue?.includes(term);
        });
      });
    // const isDateRangeMatch =
    //   (!fromDateFilter ||
    //     new Date(item?.OM_LOC_CREATED_DATE) >= new Date(fromDateFilter)) &&
    //   (!toDateFilter ||
    //     new Date(item?.OM_LOC_CREATED_DATE) <= new Date(toDateFilter));
    // return textMatch && isDateRangeMatch;
    return textMatch;
  });
  const handleRowClick = (rowData) => {
    setCategoryName(rowData.OM_LOC_NAME);
    setCategoryCode(rowData.OM_LOC_CODE);
    setWareHouseCode(rowData.OM_WAREHOUSE_CODE);

    setLength(rowData.OM_LOC_LENGTH);
    setBreadth(rowData.OM_LOC_BREADTH);
    setHeight(rowData.OM_LOC_HEIGHT);
  };
  const handleWareHouseChange = (selectedOption) => {
    if (selectedOption) {
      setWareHouseCode(selectedOption.value);
    } else {
      setWareHouseCode(""); // Clear make name if no option is selected
    }
  };

  if (isloading) {
    return <Loading />;
  }
  return (
    <div>
      {" "}
      <>
        <AllPageNavbar />{" "}
        <header className="header__of__main lg:flex  justify-between items-end px-3 ">
          <h1 className="header__of__page"> Location Master</h1>
          <div className="my-1">
            <button
              className=" bg-[#002d62]  text-white rounded-md px-2 h-[30px]  lg:ml-2 mr-2 "
              onClick={() => submitCategory("new")}
            >
              Save
            </button>
            <button
              className=" bg-[#002d62]  text-white rounded-md px-2 h-[30px]  lg:ml-2 mr-2 "
              onClick={() => submitCategory("update")}
            >
              Update
            </button>
            <button
              className=" bg-[#002d62]  text-white rounded-md px-2 h-[30px]  lg:ml-2 mr-2 "
              onClick={() => {
                window.location.reload();
              }}
            >
              Reset
            </button>
          </div>
        </header>
        <div>
          <article>
            <section className=" shadow-slate-100	m-2 bg-gray-100 border-black rounded	p-2 ">
              <aside className="lg:flex lg:my-1 lg:mb-3">
                <p className="lg:w-[130px]">Location Code </p>
                <input
                  className="w-[200px] lg:mr-[50px]"
                  value={categoryCode}
                  onChange={(e) => {
                    setCategoryCode(e.target.value);
                  }}
                ></input>
                <p className="lg:w-[130px]">Location Name</p>{" "}
                <input
                  className="w-[250px] lg:mr-[50px]"
                  type="text"
                  value={categoryName}
                  onChange={(e) => {
                    setCategoryName(e.target.value);
                  }}
                ></input>
                <p>Date </p>
                <input
                  type="date"
                  className="w-[130px] lg:mx-1"
                  value={datePush}
                  onChange={(e) => {
                    setDatePush(e.target.value);
                  }}
                ></input>
                {/* <p className="lg:w-[130px] lg:text-right">
                  Processed By &nbsp;
                </p>
                <input
                  className="w-[150px] lg:mr-[50px]"
                  value={LoggedInUserName}
                ></input>{" "} */}
              </aside>
              <aside className="lg:flex items-center ">
                <p className="lg:w-[130px]">Ware House Code</p>
                {/* <input
                  className="w-[200px] lg:mr-[50px]"
                  required
                  value={wareHouseCode}
                  onChange={(e) => {
                    setWareHouseCode(e.target.value);
                  }}
                ></input> */}
                <div>
                  <Select
                    className="w-[200px] lg:mr-[50px] text-sm"
                    options={gettinWarehouseCode?.map((make) => ({
                      value: make.WAREHOUSECODE,
                      label: make.WAREHOUSECODE,
                    }))}
                    value={
                      wareHouseCode
                        ? { label: wareHouseCode, value: wareHouseCode }
                        : { label: "select", value: "" }
                    }
                    onChange={handleWareHouseChange}
                    placeholder="Select Make"
                  />
                </div>
                <div className="lg:flex items-center gap-2">
                  <p>Length:</p>
                  <input
                    className="w-[100px] block"
                    type="number"
                    value={length}
                    onChange={handleLengthChange}
                  />
                  <p>Breadth:</p>
                  <input
                    className="w-[100px] block"
                    type="number"
                    value={breadth}
                    onChange={handleBreadthChange}
                  />
                  <p>Height:</p>
                  <input
                    className="w-[100px] block"
                    type="number"
                    value={height}
                    onChange={handleHeightChange}
                  />
                </div>
              </aside>
            </section>
          </article>
        </div>
        <section className="table__pos__app w-[98%]  ">
          <div className="lg:flex items-center">
            <p className="lg:mx-2 text-[16px]">Search</p>
            <input
              className="w-[200px] lg:mr-[50px]"
              required
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            ></input>
            {/* <section className="lg:flex gap-3 items-center  lg:px-[20px] text-[16px]">
              <div className="lg:ml-[10px] md:ml-0 flex items-center ">
                <p className="w-[60px] lg:w-auto py-1 lg:mx-1">From&nbsp;</p>
                <input
                  type="date"
                  value={fromDateFilter}
                  onChange={handleFromDateFilterChange}
                />
              </div>
              <div className="lf:ml-[10px] md:ml-0 flex items-center ">
                <p className="w-[60px] lg:w-auto py-1 lg:mx-1">To&nbsp;</p>
                <input
                  type="date"
                  value={toDateFilter}
                  onChange={handleToDateFilterChange}
                />
              </div>
            </section> */}
          </div>

          <table class="custom-table ">
            <thead class="header-cell">
              <tr>
                <td> SNo</td>
                <td>LOCATION CODE</td>
                <td> NAME</td>
                <td> WAREHOUSE CODE</td>
                {/* <td>LOCATION UOM</td> */}
                <td> HEIGHT</td>
                <td> LENGTH</td>
                <td> BREADTH</td>
                {/* <td> COMP CODE</td>
                <td> CREATED BY</td>
                <td> CREATED DATE</td> */}
              </tr>
            </thead>
            <tbody>
              {Array.isArray(filteredData) && filteredData.length > 0 ? (
                filteredData.map((value, index) => (
                  <tr
                    key={index}
                    className="table-row"
                    onClick={() => handleRowClick(value)}
                  >
                    <td className="text-right">{index + 1}</td>{" "}
                    {/* Add 1 to start index from 1 */}
                    <td>{value.OM_LOC_CODE ? value.OM_LOC_CODE : "N/A"}</td>
                    <td>{value.OM_LOC_NAME ? value.OM_LOC_NAME : "N/A"}</td>
                    <td>
                      {value.OM_WAREHOUSE_CODE
                        ? value.OM_WAREHOUSE_CODE
                        : "N/A"}
                    </td>
                    {/* <td>{value.OM_LOC_UOM ? value.OM_LOC_UOM : "N/A"}</td> */}
                    <td className="text-right">
                      {value.OM_LOC_HEIGHT ? value.OM_LOC_HEIGHT : "N/A"}
                    </td>
                    <td className="text-right">
                      {value.OM_LOC_LENGTH ? value.OM_LOC_LENGTH : "N/A"}
                    </td>
                    <td className="text-right">
                      {value.OM_LOC_BREADTH ? value.OM_LOC_BREADTH : "N/A"}
                    </td>
                    {/* <td>{value.OM_LOC_COMP_CODE}</td>
                    <td>{value.OM_LOC_CREATED_BY}</td>
                    <td className="text-right">{value.OM_LOC_CREATED_DATE}</td> */}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="9">No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </section>
      </>
    </div>
  );
};

export default LocationCreation;
