import { React, useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import axios from "axios";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import Loading from "../Components/Loading";
import ExcelJS from "exceljs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { RiFileExcel2Line } from "react-icons/ri";

function ReceiptReports() {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const BASEURL_GLOBAL = process.env.REACT_APP_BASEURL_GLOBAL;
  const divisionState = useSelector((state) => state.changeDivision);
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [receipts, setReciepts] = useState([]);
  const [isoading, setisloading] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const currentDate = new Date();
  const startOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const localStartOfMonth = new Date(
    startOfMonth.getTime() - startOfMonth.getTimezoneOffset() * 60000
  );
  const [fromDate, setFromDate] = useState(localStartOfMonth.toISOString());
  const [toDate, setToDate] = useState(currentDate.toISOString());
  const [paymodes, setPaymodes] = useState([]);
  const [selpaymentmode, setselpaymentmode] = useState("");

  useEffect(() => {
    fetchReceipts();
  }, []);

  const filtData = receipts.filter((item) => {
    const sDate = item.RECEIPTDATE;
    const eDate = item.RECEIPTDATE;

    if (!fromDate && !toDate && !selpaymentmode) {
      return true;
    }

    const dateMatch =
      (!fromDate || sDate >= fromDate) && (!toDate || eDate <= toDate);

    // Payment mode filter
    const paymentModeMatch = !selpaymentmode || item.PAYMODE === selpaymentmode;

    return dateMatch && paymentModeMatch;
  });

  useEffect(() => {
    let total = 0;
    filtData.forEach((item) => {
      total += parseFloat(item.RECEIPTAMOUNT);
    });
    setTotalAmount(total);
  }, [filtData]);

  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "200px",
      maxWidth: "200px",
      marginTop: "0px",
      minHeight: "initial",
      height: "30px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      backgroundColor: "white",
      color: "black",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  const Excelreceipt = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sales Report Paid");
    const rows = document.querySelectorAll(".table_1 tbody tr");

    const columnWidths = [15, 50, 25, 25, 25, 15];

    // Set column widths for the worksheet
    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width;
    });

    // Add headers
    const headerRow = worksheet.addRow([
      "RECEIPT NO",
      "RECEIPT DATE",
      "PAY MODE",
      "RECEIPT AMT",
      "PAY REF NO",
      "CREATED BY",
      "DIVISION",
    ]);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    // Add rows from the table
    rows.forEach((row) => {
      const rowData = [];
      row.querySelectorAll("td").forEach((cell) => {
        rowData.push(cell.textContent);
      });
      worksheet.addRow(rowData);
    });

    // Create a blob from the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "Sales_Report_Paid.xlsx";
      link.click();
    });
  };

  const PdfReceipts = () => {
    const doc = new jsPDF();

    doc.text("Receipts Report", 10, 10);

    const columns = [
      "RECEIPT NO",
      "RECEIPT DATE",
      "PAY MODE",
      "RECEIPT AMT",
      "PAY REF NO",
      "CREATED BY",
      "DIVISION",
    ];
    const data = filtData.map((stock) => [
      stock.RECEIPTNO,
      moment(stock.RECEIPTDATE).format("DD/MM/YYYY"),
      stock.PAYMODE,
      stock.RECEIPTAMOUNT,
      stock.REFNO,
      stock.CREATEDBY,
      stock.DIVISION,
    ]);

    doc.autoTable({
      head: [columns],
      body: data,
      startY: 20,
      showHead: "firstPage",
    });

    doc.save("Receipts_Report.pdf");
  };

  function fetchReceipts() {
    setisloading(true);

    axios
      .get(
        // `${BASEURL}/getAllReceipts`,
        `${BASEURL_GLOBAL}/globalViewHandler?viewname=649&DIVISION=${divisionState}`,
        // { DIVISION: divisionState },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setReciepts(response.data);
      })
      .catch((err) => {
        console.log(err);
        setisloading(false);
      });

    axios
      // .get(`${BASEURL}/getPaymentModes`, {
      .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=493`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response1) => {
        setPaymodes(response1.data);
        setisloading(false);
      })
      .catch((err) => {
        console.log(err);
        setisloading(false);
      });
  }

  const renderList = (
    <>
      <section className="lg:flex justify-between">
        <div className="flex items-center mx-8 justify-between mt-3 gap-5">
          <div>
            <label>From Date:</label>
            <input
              type="date"
              value={fromDate.split("T")[0]}
              onChange={(e) => setFromDate(e.target.value)}
              className="w-35 h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
            />
          </div>
          <div>
            <label>To Date:</label>
            <input
              type="date"
              value={toDate.split("T")[0]}
              onChange={(e) => setToDate(e.target.value)}
              className="w-35 h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
            />
          </div>
        </div>
        <div className="flex items-center mx-8 justify-between mt-3 gap-5">
          <div className="flex items-center">
            <p className="font-bold">Payment mode : &nbsp;</p>
            <div className="w-[200px]">
              <Select
                styles={customStyless}
                onChange={(e) => {
                  setselpaymentmode(e.value);
                }}
                value={
                  selpaymentmode
                    ? { label: selpaymentmode, value: selpaymentmode }
                    : { label: "", value: "" }
                }
                options={[
                  { label: "", value: "" }, // Empty option
                  ...paymodes.map((item) => ({
                    label: item.PAYMODE,
                    value: item.PAYMODE,
                  })),
                ]}
              />
            </div>
            <div>
              <RiFileExcel2Line onClick={Excelreceipt} size={35} />
            </div>
            <div>
              {" "}
              <BsFileEarmarkPdf onClick={PdfReceipts} size={35} />
            </div>
          </div>
        </div>
      </section>
      <aside className="table__pos__app ">
        <table class="custom-table table_1">
          <thead class="header-cell">
            <td>RECEIPT NO</td>
            <td>RECEIPT DATE</td>
            <td>PAY MODE</td>
            <td>RECEIPT AMT</td>
            <td>PAY REF NO</td>
            <td>CREATED BY</td>
            <td>DIVISION</td>
          </thead>
          <tbody>
            {filtData.map((item) => {
              return (
                <tr key={item.RECEIPTNO} class="table-row">
                  <td className="text-right">{item.RECEIPTNO}</td>
                  <td className="text-right">
                    {moment(item.RECEIPTDATE).format("DD/MM/YYYY")}
                  </td>
                  <td>{item.PAYMODE}</td>
                  <td className="text-right">
                    {item.RECEIPTAMOUNT
                      ? item.RECEIPTAMOUNT.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : "N/A"}
                  </td>
                  <td>{item.REFNO ? item.REFNO : "N/A"}</td>
                  <td>{item.CREATEDBY ? item.CREATEDBY : "N/A"}</td>
                  <td>{item.DIVISION ? item.DIVISION : "N/A"}</td>
                </tr>
              );
            })}
          </tbody>
        </table>{" "}
      </aside>
      <h2 className="text-red-700">
        Total :{" "}
        {totalAmount
          ? totalAmount.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : ""}
      </h2>
    </>
  );

  return (
    <>
      <Navbar />
      {isoading ? <Loading /> : renderList}
    </>
  );
}

export default ReceiptReports;
