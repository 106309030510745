import React, { useEffect, useState } from "react";
import axios, { toFormData } from "axios";
import { useSelector } from "react-redux";
import "../CSS/CustomerMaster.css";
import AllPageNavbar from "../Components/AllPageNavbar";
import Loading from "../Components/Loading";
import Select from "react-select"; // Import react-select
import { useNavigate, useLocation } from "react-router-dom";
import { BiEditAlt } from "react-icons/bi";
const CustomerMaster = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const BASEURL_GLOBAL = process.env.REACT_APP_BASEURL_GLOBAL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const navigate = useNavigate();
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const state = useSelector((state) => state.changeTheState);
  const UserEmail = state?.user ? state?.user?.emailId?.split("@")[0] : "N/A";
  const [search, setSearch] = useState("");
  const [ClientMaster, setClientMaster] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [Type, setType] = useState("new");
  const [ClientCode, setClientCode] = useState("");
  const [ClientName, setClientName] = useState("");
  const [ClientEmail, setClientEmail] = useState("");
  const [clientPostalCode, setCleintPostalCode] = useState("");
  const [clientAddress, setClientAddress] = useState("");
  const [cleintMobile, setClientMobile] = useState("");
  const [clientCashorCredit, setClientCashorCredit] = useState("");
  const [clientType, setClientType] = useState(0);
  const [cashCardSearch, setCashCardSearch] = useState("");

  useEffect(() => {
    masterData();
  }, []);

  const filteredData = ClientMaster.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0 && !cashCardSearch) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return ["CLIENTCODE", "CLIENTNAME", "CLIENTEMAIL"].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    const cardCashMatch =
      !cashCardSearch ||
      !cashCardSearch.length ||
      cashCardSearch.toLowerCase() === item?.CASHCREDIT?.toLowerCase();

    return textMatch && cardCashMatch;
  });

  function masterData() {
    setisloading(true);
    // getClientlist;
    axios
      .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=315`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setisloading(false);
        setClientMaster(response.data);
      })
      .catch((err) => {
        setisloading(false);
        console.log("Error while fetching Client Details", err);
      });
  }

  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleEdit = (item) => {
    setClientCode(item.CLIENTCODE || "");
    setClientName(item.CLIENTNAME || "");
    setClientEmail(item.CLIENTEMAIL || "");
    setClientAddress(item.ADDRESSS || "");
    setCleintPostalCode(item.POSTALCODE || "");
    setClientMobile(item.MOBILE || "");
    setClientCashorCredit(item.CASHCREDIT || "");
    setClientType(item.CLIENTTYPE === true ? 1 : 0);
  };

  // /insertClient/${Type}
  // /globalSpHandler?spname=110&successstatus=true
  function SaveClient() {
    setisloading(true);
    axios
      .post(
        `${BASEURL_GLOBAL}/globalSpHandler?spname=110`,
        {
          CLIENTCODE: ClientCode,
          CLIENTNAME: ClientName,
          CLIENTCASHCREDIT: clientCashorCredit,
          CLIENTPOSTALCODE: clientPostalCode,
          CLIENTADDRESS: clientAddress,
          CLIENTMOBILE: cleintMobile,
          CLIENTTYPE: clientType,
          CLIENTCATEGORY: "",
          PASSWORD: "",
          CLIENTEMAIL: ClientEmail,
          DEPOSIT: 0,
          PROJECT: "",
          CONTACTNUMBER: "",
          DOB: "",
          EMERGENCYCONTACTNAME: "",
          EMERGENCYCONTACTPHONE: "",
          GUARDIANCONTACTNAME: "",
          GUARDIANEMAIL: "",
          GUARDIANBOX: "",
          GUARDIANPHYSICALADDRESS: "",
          GUARDIANHOMEADDRESS: "",
          GUARDIANNAME: "",
          GUARDIANIDPASSPORT: "",
          STUDENTEMAIL: "",
          STUDENTIDPASSPORT: "",
          STUDENTLASTNAME: "",
          STUDENTUNIVERSITY: "",
          CREATEDBY: UserEmail,
          CLIENTIMAGE: "",
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        alert(response.data.message);
        setisloading(false);
        setType("new");
        ClearInput();
      })
      .catch((error) => {
        console.error("Error saving client:", error);
        alert(error?.response?.data?.message);
        setisloading(false);
        setType("new");
        ClearInput();
      });
  }
  function ClearInput() {
    setClientCode("");
    setClientName("");
    setClientEmail("");
    setCleintPostalCode("");
    setClientMobile("");
    setClientCashorCredit("");
    setClientAddress("");
    masterData();
  }

  if (isloading) {
    return <Loading />;
  }
  return (
    <div>
      <div className="toHIde__when__Print">
        <AllPageNavbar />
        <div>
          <div className="div">
            <div className="center-container_1">
              <div className="form-container_1  ">
                <main className="form_info ">
                  <div className="flex justify-between">
                    <header className="header__of__main lg:flex justify-between px-3 items-center">
                      {menu ? (
                        <h1 className="header__of__page">
                          {" "}
                          {menu ? menu : "Client Master"}
                        </h1>
                      ) : (
                        <h1 className="header__of__page"> CLIENT MASTER</h1>
                      )}
                    </header>
                    <div className="savebutton">
                      <button
                        className="  border border-black bg-[#002d62] text-white rounded-md px-3   mr-1 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
                        onClick={ClearInput}
                      >
                        New
                      </button>
                      <button
                        className="  border border-black bg-[#002d62] text-white rounded-md px-3   mr-1 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
                        onClick={SaveClient}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                  <section className="m-2 h-auto p-2 bg-slate-300 rounded-md">
                    {/* Line 1 */}
                    <aside className="flex gap-4 mt-2">
                      <div className="flex gap-2">
                        <p>
                          <label className="garage_id_">CLIENT CODE </label>
                        </p>
                        <div>
                          <input
                            className="w-64 ml-[68px] border border-black rounded-md"
                            name="clientcode"
                            value={ClientCode || ""}
                            type="text"
                            onChange={(e) => {
                              setClientCode(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <p>
                          <label className="garage_id_">CLIENT NAME </label>
                        </p>
                        <div>
                          <input
                            className="w-64 ml-3 border border-black rounded-md"
                            value={ClientName || ""}
                            name="clientname"
                            type="text"
                            onChange={(e) => {
                              setClientName(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <p>
                          <label className="garage_id_">CLIENT EMAIL </label>
                        </p>
                        <div>
                          <input
                            value={ClientEmail || ""}
                            name="clientemail"
                            className="w-64 border border-black rounded-md"
                            type="text"
                            onChange={(e) => {
                              setClientEmail(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </aside>
                    {/* Line 2 */}
                    <aside className="flex gap-4 mt-2">
                      <div className="flex gap-2">
                        <p>
                          <label className="garage_id_">
                            CLIENT POSTAL CODE{" "}
                          </label>
                        </p>
                        <div>
                          <input
                            name="clientemail"
                            className="w-64 border border-black rounded-md"
                            type="text"
                            value={clientPostalCode || ""}
                            onChange={(e) => {
                              setCleintPostalCode(e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      <div className="flex gap-2">
                        <p>
                          <label className="garage_id_">CLIENT MOBILE </label>
                        </p>
                        <div>
                          <input
                            name="clientemail"
                            className="w-64 border border-black rounded-md"
                            type="text"
                            value={cleintMobile || ""}
                            onChange={(e) => {
                              setClientMobile(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <p>
                          <label className="garage_id_">CASH/CREDIT </label>
                        </p>
                        <div>
                          <Select
                            className="w-48"
                            value={
                              clientCashorCredit
                                ? {
                                    label: clientCashorCredit,
                                    value: clientCashorCredit,
                                  }
                                : { label: "Select CASH/CREDIT", value: "" }
                            }
                            onChange={(e) => {
                              setClientCashorCredit(e.value);
                            }}
                            options={[
                              { label: "Select CASH/CREDIT", value: "" },
                              { label: "CASH", value: "CASH" },
                              { label: "CREDIT", value: "CREDIT" },
                            ]}
                          />
                        </div>
                      </div>
                    </aside>
                    {/* Line 3 */}
                    <aside className="flex gap-4 mt-2">
                      <div className="flex gap-2">
                        <p>
                          <label className="garage_id_">CLIENT ADDRESS </label>
                        </p>
                        <div>
                          <textarea
                            className="w-[700px] ml-10 h-20 resize-none border border-black rounded-md"
                            name="password"
                            type="text"
                            value={clientAddress || ""}
                            onChange={(e) => {
                              setClientAddress(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </aside>
                  </section>
                </main>
              </div>
            </div>
          </div>
          <article>
            <div className="lg:flex items-center justify-between mt-2">
              <div className="flex mt-2">
                <p className="lg:mx-2 text-[16px]">Search</p>
                <input
                  className="w-64 border border-black rounded-md"
                  required
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                ></input>
                <p className="lg:mx-2 text-[16px]">CASH / CREDIT</p>
                <Select
                  className="w-56"
                  value={
                    cashCardSearch
                      ? {
                          label: cashCardSearch,
                          value: cashCardSearch,
                        }
                      : { label: "Select CASH/CREDIT", value: "" }
                  }
                  onChange={(e) => {
                    setCashCardSearch(e.value);
                  }}
                  options={[
                    { label: "Select CASH/CREDIT", value: "" },
                    { label: "CASH", value: "CASH" },
                    { label: "CREDIT", value: "CREDIT" },
                  ]}
                />
              </div>
            </div>
            <div className="  overflow-auto m-1  p-[20px] for__table__width__full">
              <table className="table_1  table__size__reducer ">
                <thead>
                  <tr style={{ fontWeight: "400" }}>
                    <td>CLIENT CODE</td>
                    <td>CLIENT NAME</td>
                    <td>CLIENT EMAIL</td>
                    <td>CASH/CREDIT</td>
                    <td>EDIT</td>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.length > 0 ? (
                    filteredData.map((item, index) => {
                      return (
                        <tr key={index} className="table-row">
                          <td>
                            {item?.CLIENTCODE ? item?.CLIENTCODE : "N/A"}{" "}
                          </td>
                          <td>
                            {item?.CLIENTNAME ? item?.CLIENTNAME : "N/A"}{" "}
                          </td>
                          <td>
                            {item?.CLIENTEMAIL ? item?.CLIENTEMAIL : "N/A"}{" "}
                          </td>
                          <td>
                            {item?.CASHCREDIT ? item?.CASHCREDIT : "N/A"}{" "}
                          </td>
                          <td
                            style={{ fontSize: "20px", cursor: "pointer" }}
                            onClick={() => {
                              handleEdit(item);
                              scrollToSection();
                              setType("update");
                            }}
                          >
                            <BiEditAlt />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="10">No data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
};

export default CustomerMaster;
