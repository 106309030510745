import { React, useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import axios from "axios";
import { IoFastFoodOutline } from "react-icons/io5";
import Loading from "../Components/Loading";
import { useNavigate } from "react-router-dom";

const Inventory = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const BASEURL_GLOBAL = process.env.REACT_APP_BASEURL_GLOBAL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [category, setcategory] = useState([]);
  const [itemdetails, setItemdetails] = useState([]);
  const [selcategory, setselcategory] = useState("");
  const [isloading, setisloading] = useState(true);
  const [table, setistable] = useState(true);
  const [ShowTable, setShowTable] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    getAllCategories();
  }, []);

  const getAllCategories = () => {
    axios
      // .get(`${BASEURL}/getallcategories`, {
      .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=287`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setcategory(response.data);
        setisloading(false);
        setistable(false);
      })
      .catch((error) => {
        setisloading(false);
        setistable(false);
        console.error("Error fetching Main Categories:", error);
      });
  };

  const getItemDetails = (value) => {
    axios
      .get(
        // `${BASEURL}/getItemDetails`,
        `${BASEURL_GLOBAL}/globalViewHandler?viewname=287&MAINCATEGORY=${value}`,
        // {
        //   category: value,
        // },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setItemdetails(response.data);
        setShowTable(true);
        setistable(false);
      })
      .catch((error) => {
        setistable(false);
        console.error("Error fetching List Of Items:", error);
      });
  };

  return (
    <>
      <Navbar />
      {isloading ? (
        <Loading />
      ) : (
        <div>
          {/* <button
            className="mx-2 p-1 bg-slate-500 text-white rounded"
            onClick={() => {
              navigate("/UploadCategoryImage");
            }}
          >
            Upload Category Image
          </button> */}
          <div className="w-auto flex flex-row">
            {/* left side */}
            <div className="w-[620px] flex flex-wrap overflow-y-auto max-h-[500px]">
              {category.map((item, index) => (
                <aside
                  key={index}
                  className={`rounded-md border-slate-200 border shadow-xl text-center p-2 ml-2 mt-2 cursor-pointer w-24 flex flex-col items-center ${
                    selcategory === item.MAINCATEGORY
                      ? "bg-blue-200"
                      : "bg-white"
                  }`}
                  onClick={() => {
                    setistable(true);
                    getItemDetails(item.MAINCATEGORY);
                    setselcategory(item.MAINCATEGORY);
                  }}
                >
                  {item.CATEGORYIMAGE?.data ? (
                    <>
                      {" "}
                      <img
                        className="text-amber-950"
                        src={URL.createObjectURL(
                          new Blob([new Uint8Array(item.CATEGORYIMAGE?.data)], {
                            type: "image/jpeg",
                          })
                        )}
                      />
                    </>
                  ) : (
                    <IoFastFoodOutline size={50} className="text-amber-950" />
                  )}

                  <p className="text-xs">{item.MAINCATEGORY}</p>
                </aside>
              ))}
            </div>

            {/* right side */}

            <div className="w-[800px] h-auto pb-2">
              {table ? (
                <Loading />
              ) : (
                ShowTable && (
                  <aside className="table__pos__app ">
                    <table class="custom-table w-[98%]">
                      <thead class="header-cell">
                        <tr>
                          <td className="text-left">ITEM CODE</td>
                          <td className="text-left">PRODUCT NAME</td>
                          <td className="text-right">RATE</td>
                          <td className="text-right">QUANTITY</td>
                          <td className="text-left pl-2">UOM</td>
                        </tr>
                      </thead>
                      <tbody>
                        {itemdetails.map((item) => {
                          return (
                            <tr class="table-row" key={item.OM_ITEM_CODE}>
                              <td className="text-left">{item.OM_ITEM_CODE}</td>
                              <td
                                className="text-left"
                                style={{ maxWidth: "200px" }}
                              >
                                {item.OM_ITEM_DESCRIPTION}
                              </td>
                              <td className="text-right">
                                {item.SELLINGPRICE.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </td>
                              <td className="text-right pr-2">
                                {item.STOCKQTY}
                              </td>
                              <td className="text-left pl-2">{item.UOM}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </aside>
                )
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Inventory;
