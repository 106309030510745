import React, { useState, useEffect, useRef } from "react";
import "../CSS/Grn.css";
import axios from "axios";
import { AiOutlineClose } from "react-icons/ai";
import { BsWindowStack } from "react-icons/bs";
import { FaSave } from "react-icons/fa";
import { SiBookstack } from "react-icons/si";
import { GrDocument } from "react-icons/gr";
import { GiCrossMark } from "react-icons/gi";
import { Input } from "postcss";
import { poToGrn } from "../Redux/Action/poToGrn";
import { useNavigate, useLocation, useLoaderData } from "react-router-dom";
import { useDispatch } from "react-redux";
import "../CSS/Purchaseorder.css";
import Loading from "../Components/Loading";
import AllPageNavbar from "../Components/AllPageNavbar";
import moment from "moment";
import Select from "react-select";
import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import LoadingSpinner from "../Components/Loader/UploadLoader";

function Grn() {
  // const state = useSelector((state) => state?.loginReducer?.data);
  const state = useSelector((state) => state.changeTheState.user);
  const userName = state?.emailId?.split("@")[0].toUpperCase();
  console.log(userName);
  const divsionInitial = useSelector((state) => state?.changeDivision);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [companyLOGO, setCompanyLOGO] = useState("");
  const LoggedInUserName = state?.emailId?.split("@")[0].toUpperCase();
  const poData = useSelector((state) => state.poToGrnReducer);
  const [GrnNo, setGrnNO] = useState("");
  const [date, setDate] = useState(new Date().toISOString());
  const [isLoading, setIsLoading] = useState(false);
  const BASEURL = process.env.REACT_APP_BASEURL;
  const BASEURL_GLOBAL = process.env.REACT_APP_BASEURL_GLOBAL;
  const BASEURL_REPORTS = process.env.REACT_APP_BASEURL_REPORTS;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [cashsalepopup, setcashsalepopup] = useState(false);
  const [search, setSearch] = useState("");
  const [CurrentGrnNumber, SetCurrentGrnNumber] = useState([]);
  const [getGrnno, setgetGrnno] = useState("");
  const [StartPoNum, SetStartPoNum] = useState("");
  const [currentPonum, SetcurrentPonum] = useState("");
  const [getGrndetails, setgetGrndetails] = useState([]);
  const [getItemDetails, setgetItemDetails] = useState([]);
  const [itempopup, setItempopup] = useState(false);
  const [location, setLocation] = useState([]);
  const [selGrnLocation, setSelGrnLocation] = useState("");
  const [itemName, setItemName] = useState("");
  const [itemCode, setItemCode] = useState("");
  const [quantity, setQuantity] = useState("");
  const [supplierInvoiceDate, setSupplierInvoiceDate] = useState(null);
  // const [vat, setVat] = useState("");
  const [rate, setRate] = useState("");
  const [uom, setUom] = useState("");
  const [itemsInGrid, setItemsInGrid] = useState(poData?.poDetails);
  const [historic, setHistoric] = useState([]);
  const [ShowTable, setShowTable] = useState(true);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [supplierCurrency, setSupplierCurrency] = useState("");
  const [supplierExchRate, setSupplierExchRate] = useState("");
  const [grnRefDoc, setGrnRefDoc] = useState("");
  const [supplierList, setSupplierList] = useState([]);
  const [supplierCode, setSupplierCode] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [supplierInvoice, setSupplierInvoice] = useState("");
  const [grnNotes, setGrnNotes] = useState("");
  const [receivedby, setReceivedBy] = useState([]);
  const [selReceivedBy, setSelReceivedBy] = useState("");
  const [grandTotal, setGrandTotal] = useState("");
  const [listOfItems, setListOfItems] = useState([]);
  // const [totalQuantity, setTotalQuantity] = useState(0);
  // const [totalAmount, setTotalAmount] = useState(0);
  // const division = useSelector((state) => state?.DivisionReducer);
  const locations = useLocation();
  const ponofromDashboard = locations?.state?.pono;
  const [selectedRow, setSelectedRow] = useState({
    // PONO: "",
    OT_GRN_NO: "",
    OT_GRN_DATE: "",
    OT_GRN_SUPPLIER_CODE: "",
    OT_GRN_SUPPLIER_CURRENCY: "",
    OT_GRN_SUPPLIER_EXCH_RATE: "",
    OT_GRN_RECIEVED_BY: "",
    OT_GRN_INDUSTRY: "",
  });

  const [pdfData, setPdfData] = useState(null);
  const [LoadingScreenBlock, setLoadingScreenBlock] = useState(false);
  const [hist, setHist] = useState(false);
  const [reportName, setReportName] = useState("");
  const [reportPath, setReportPath] = useState("");

  function handleGrnToPo(PONO) {
    // getPoHeaders;
    axios
      .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=645&PONO=${PONO}`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        const headerObject = {
          PONO: response.data[0]?.PONO,
          // SUPPLIERCODE: item.SUPPLIERCODE,
          ["SUPPLIER NAME"]: response.data[0]?.["SUPPLIER NAME"],
          // SUPPLIERCURRENCY: item.CURRENCY,
          DIVISION: response.data[0]?.DIVISION,
          PODATE: response.data[0]?.PODATE,
          VEHICLE: response.data[0]?.VEHICLE,
          PONOTES: response.data[0]?.PONOTES,
          POMODEOFPAY: response.data[0]?.POMODEOFPAY,
        };
        // getPoChilds;
        axios
          .get(
            `${BASEURL_GLOBAL}/globalViewHandler?viewname=644&PONO=${PONO}`,
            {
              headers: {
                "auth-token": authToken,
                "session-token": sessiontoken,
              },
            }
          )
          .then((response) => {
            const updatedArray = response.data?.map((item) => {
              // const excVat = Number(Number(item.ITEMRATE) * Number(item.POITEMQTY));
              // const vatamt = (excVat * Number(vat)) / 100;
              // const incVat = excVat + vatamt;
              return {
                POITEMCODE: item.POITEMCODE,
                POITEMDESCRIPTION: item.POITEMDESCRIPTION,
                POITEMQTY: Number(item.POITEMQTY),

                POITEMAMOUNT: Number(item.POITEMAMOUNT),
                POVAT: Number(item.POVAT),
                POITEMRATE: Number(item.POITEMRATE),

                POITEMUOM: item.POITEMUOM,
                POVATABLE: item.POVATABLE,
                POAPPROVED: item.POAPPROVED,
              };
            });

            const reduxItem = {
              poHeader: headerObject,
              poDetails: updatedArray,
            };

            dispatch(poToGrn(reduxItem));
            navigate("/POProcessing");
          })
          .catch((err) => {
            console.log("Error While fetching PO Items", err);
          });
      })
      .catch((err) => {
        console.log("Error while fetching PO headers", err);
      });
  }
  let totalQuantity = 0;
  let totalAmount = 0;
  const generateInvoicePDF = (invoiceData, docno) => {
    const doc = new jsPDF();

    let yPos = 30;
    doc.setFontSize(17);
    doc.text("GOODS RECEIPT NOTE", 105, yPos, {
      align: "center",
    });
    yPos += 15;
    doc.setFontSize(9);
    doc.text(`GRN NO : ${GrnNo ? GrnNo : ""}`, 15, yPos);
    doc.text(`SUPPLIER NAME: ${supplierName ? supplierName : ""}`, 105, yPos, {
      align: "center",
    });
    doc.text(
      `PO NO & DATE:  ${
        date && grnRefDoc
          ? `${grnRefDoc}    ${moment(date).format("DD/MM/YYYY")}`
          : ""
      }`,
      150,
      yPos
    );
    yPos += 8;
    doc.text(
      `GRN DATE : ${date ? moment(date).format("DD/MM/YYYY") : ""}`,
      15,
      yPos
    );
    doc.text("DIVISION:", 150, yPos);
    yPos += 8;
    doc.text(
      `INVOICE NO:  ${supplierInvoice ? supplierInvoice : ""}`,
      15,
      yPos
    );
    doc.text(`LOCATION: ${selGrnLocation ? selGrnLocation : ""}`, 150, yPos);
    yPos += 8;
    doc.text(
      `INVOICE DATE:  ${
        supplierInvoiceDate
          ? moment(supplierInvoiceDate).format("DD/MM/YYYY")
          : ""
      }`,
      15,
      yPos
    );
    doc.text(`CREATED BY: TEST`, 150, yPos);

    yPos += 10;
    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.text("SNo", 15, yPos);
    doc.text("ITEM DESCRIPTION", 30, yPos);
    doc.text("TRANS QTY ", 110, yPos);
    doc.text("UOM", 130, yPos);
    doc.text("GRN RATE", 150, yPos);
    doc.text("AMOUNT", 180, yPos);

    // Draw vertical lines for each column
    const verticalLineStart1 = 60; // X-coordinate for the vertical line after "Service Name" column
    const verticalLineStart2 = 110; // X-coordinate for the vertical line after "Qty" column
    const tableStartY = yPos - 5; // Starting Y-coordinate of the table
    let tableEndY; // Ending Y-coordinate of the table (to be calculated)

    // Draw a horizontal line below the headers
    yPos += 5;
    doc.setLineWidth(0.5); // Set a normal line width
    doc.line(10, yPos, 200, yPos);
    let maxAmountWidth = 0;
    itemsInGrid.forEach((item, index) => {
      const amountText = item.Rate ? item.Rate.toString() : 0;
      const amountWidth =
        (doc.getStringUnitWidth(amountText) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      if (amountWidth > maxAmountWidth) {
        maxAmountWidth = amountWidth;
      }
    });
    const tableWidthx = 100; // Width of the table
    const tableXx = 200 - tableWidthx; // X-coordinate of the table
    const amountX = tableXx + tableWidthx - maxAmountWidth - 20;
    itemsInGrid.forEach((item, index) => {
      yPos += 10;
      doc.text((index + 1).toString(), 15, yPos);
      doc.text(item?.ItemName, 30, yPos);
      doc.text(item?.Quantity.toString(), 110, yPos);
      doc.text(`${item?.UOM ? item?.UOM : ""}`, 130, yPos);
      doc.text(
        item?.Rate.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        150,
        yPos
      );
      doc.text(
        item?.TotalAmount.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        180,
        yPos
      );
      tableEndY = yPos + 5;
    });
    doc.setLineWidth(0.5); // Set a normal line width
    // doc.line(verticalLineStart1, tableStartY, verticalLineStart1, tableEndY);
    // doc.line(verticalLineStart2, tableStartY, verticalLineStart2, tableEndY);

    // Draw a horizontal line above the table
    doc.line(10, tableStartY, 200, tableStartY);

    // Draw a horizontal line below the table
    doc.line(10, tableEndY, 200, tableEndY);

    // Draw vertical lines to cover the left and right boundaries of the table
    doc.line(10, tableStartY, 10, tableEndY);
    doc.line(200, tableStartY, 200, tableEndY);
    yPos += 10;
    doc.setFontSize(12);

    itemsInGrid.map((item) => {
      item.Quantity ? (totalQuantity += item.Quantity) : (totalQuantity += 0);
    });
    doc.text(`TOTAL GRN QUANTITY : ${totalQuantity}`, 80, yPos);
    doc.setTextColor(255, 0, 0); // Red color

    itemsInGrid.map((item) => {
      item.TotalAmount ? (totalAmount += item.TotalAmount) : (totalAmount += 0);
    });
    doc.text(
      ` ${totalAmount.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
      180,
      yPos
    );
    doc.setTextColor(0, 0, 0); // Black color
    yPos += 15;
    doc.setFontSize(8);
    doc.text("Received By:__________________", 15, yPos);
    doc.text("Sign:__________________", 65, yPos);
    doc.text("Date:__________________", 115, yPos);
    doc.text("Time:__________________", 165, yPos);
    yPos += 15;
    doc.text("Checked By:__________________", 15, yPos);
    doc.text("Sign:__________________", 65, yPos);
    doc.text("Date:__________________", 115, yPos);
    doc.text("Time:__________________", 165, yPos);
    yPos += 80;
    doc.setFontSize(10);
    doc.text("Powered by Genius Business System", 105, yPos, {
      align: "center",
    });
    yPos += 5;
    doc.setFontSize(10);
    doc.text("Marstrack ERP", 105, yPos, { align: "center" });
    yPos += 5;
    doc.setFontSize(10);
    doc.textWithLink("www.gbsafrica.net", 105, yPos, { align: "center" });
    yPos += 5;

    // doc.save("grn.pdf");

    // setTotalQuantity(totalQuantity);
    // setTotalAmount(totalAmount);
    window.print();
  };
  const handleQuantityChange1 = (index, newValue) => {
    setItemsInGrid((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[index].Quantity = newValue;
      updatedItems[index].TotalAmount = newValue * updatedItems[index].Rate;
      return updatedItems;
    });
  };

  const addItemsInObject = () => {
    const updatedArray = poData?.poDetails.map((item) => {
      // ItemCode: item.ITEMCODE,
      // ItemName: item.ITEMDESCRIPTION,
      // FixedQty: Number(item.QTYRECEIVED),
      // Quantity: Number(item.QTYRECEIVED),
      // Rate: Number(item.RATEEXCLUSIVE) + Number(item.VAT),
      // TotalAmount:
      //   (Number(item.RATEEXCLUSIVE) + Number(item.VAT)) *
      //   Number(item.QTYRECEIVED),
      // Vatable: `${item.VAT === 0 ? 0 : 1}`,
      // UOM: item.UOM,
      // FixedTotal:
      //   (Number(item.RATEEXCLUSIVE) + Number(item.VAT)) *
      //   (Number(item.POQTY) - Number(item.QTYRECEIVED)),
      // Rateexc: Number(item.RATEEXCLUSIVE),
      item.FixedQty = item.Quantity;
      item.FixedTotal = item.TotalAmount;
      item.PoQty = item.Quantity;
    });
    setItemsInGrid(updatedArray);
  };

  // useEffect(addItemsInObject, []);

  // function getVatPerc(){
  //   axios.get(`${BASEURL}/getvat`).then((response) => {
  //     setVat(response.data[0].VATPERC);
  //   });
  // }

  const [selectedItemRow, setSelectedItemRow] = useState({
    // PONO: "",
    "ITEM CODE": "",
    "ITEM NAME": "",
    "ITEM VATABLE": "",
    "LASTEST COST PRICE": "",
    "ITEM BASE UOM": "",
  });
  const currentDate = new Date();
  const startOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const localStartOfMonth = new Date(
    startOfMonth.getTime() - startOfMonth.getTimezoneOffset() * 60000
  );
  const [startdate, setstartdate] = useState("");
  const [enddate, setEnddate] = useState("");

  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "300px",
      maxWidth: "300px",
      marginTop: "0px",
      minHeight: "initial",
      height: "30px",
      border: "1px solid",
      paddingBottom: "1px",
      paddingTop: "0px",
      marginLeft: "0px",
      backgroundColor: "white",
      color: "black",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  useEffect(() => {
    if (itemsInGrid.length > 0) {
      let total = 0;
      itemsInGrid.forEach((item) => {
        total += parseFloat(item.TotalAmount);
      });
      setGrandTotal(total);
    }
  }, [itemsInGrid]);

  useEffect(() => {
    if (ponofromDashboard) {
      getPoDetailsFromDashboard(ponofromDashboard);
    }
  }, [ponofromDashboard]);

  function getPoDetailsFromDashboard(pono) {
    // getPoHeaders;
    axios
      .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=645&PONO=${pono}`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setGrnRefDoc(response?.data[0]?.PONO);
        setSupplierInvoiceDate(response?.data[0]?.PODATE);
        setSupplierName(response?.data[0]?.["SUPPLIER NAME"]);
        setSupplierCode(response?.data[0]?.POSUPPLIERCODE);
        setSupplierCurrency(response?.data[0]?.POCURRENCY);
        //  getExchangeRate(response?.data[0]?.POCURRENCY);
      })
      .catch((err) => {
        console.log("Error while fetching Purchase Order Headers", err);
      });
    // getPoChilds;
    axios
      .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=644&PONO=${pono}`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setViewDatatoTable1(response?.data);
        setShowTable(true);
      })
      .catch((err) => {
        console.log("Error while fetching PO Details", err);
      });
  }

  useEffect(() => {
    GetGRNnumber();
    getCompanyName();
    getLocation();
    getListOfEmployees();
    getListOfSupplier();
    getPurchaseOrderDetails();
    poObjects();
    setSupplierExchRate("1");
    getReportName();
  }, []);

  function getReportName() {
    axios
      .get(
        `${BASEURL_REPORTS}/getreportnames?DOCUMENTNAME=Good Receipt Note(GRN)`,
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setReportName(response?.data[0]?.CRNAME);
        setReportPath(response?.data[0]?.REPORTPATH);
      })
      .catch((error) => {
        console.error("Error fetching list of inventory:", error);
      });
  }

  // getNewGrnNo;
  function GetGRNnumber() {
    setIsLoading(true);
    axios
      .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=387`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setGrnNO(response.data[0]?.NEXTGRNNO);
        SetcurrentPonum(response.data[0].NEXTGRNNO);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Error while fetching New Grn Number", err);
      });
  }

  // getCompanyDetail;
  function getCompanyName() {
    setIsLoading(true);
    axios
      .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=40`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setCompanyLOGO(response?.data[0]?.ORGNAME);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Error while fetching Companyb Details", err);
      });
  }

  // getLocation;
  function getLocation() {
    // setHistoric(poData.poDetails);
    axios
      .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=634`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setLocation(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Location Details:", error);
      });
  }

  // getEmployeeList;
  function getListOfEmployees() {
    axios
      .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=611`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setReceivedBy(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Receiver Details:", error);
      });
  }

  // getListOfSupplier;
  function getListOfSupplier() {
    axios
      .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=32`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setSupplierList(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Supplier Details:", error);
      });
  }

  // getListOfInventory;
  useEffect(() => {
    if (itempopup) {
      axios
        .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=620`, {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        })
        .then((response) => {
          setgetItemDetails(response.data);
        })
        .catch((error) => {
          // Handle error
          console.error("Error fetching list of inventory:", error);
        });
    }
  }, [itempopup]);

  function setViewDatatoTable(arr) {
    const updatedArray = arr.map((item) => {
      return {
        ItemCode: item.ITEMCODE,
        ItemName: item.ITEMDESCRIPTION,
        FixedQty: Number(item.QTYRECEIVED),
        Quantity: Number(item.QTYRECEIVED),
        Rate:
          Number(item.RATEEXCLUSIVE) + Number(item.VAT) / Number(item.POQTY),
        TotalAmount:
          (Number(item.RATEEXCLUSIVE) + Number(item.VAT) / Number(item.POQTY)) *
          Number(item.QTYRECEIVED),
        Vatable: `${item.VAT === 0 ? 0 : 1}`,
        UOM: item.UOM,
        FixedTotal:
          (Number(item.RATEEXCLUSIVE) + Number(item.VAT)) *
          (Number(item.POQTY) - Number(item.QTYRECEIVED)),
        Rateexc: Number(item.RATEEXCLUSIVE),
        PoQty: Number(item.POQTY),
      };
    });
    setItemsInGrid(updatedArray);
  }

  function setViewDatatoTable1(arr) {
    const updatedArray = arr.map((item) => {
      return {
        ItemCode: item.POITEMCODE,
        ItemName: item.POITEMDESCRIPTION,
        FixedQty: Number(item.POITEMQTY),
        Quantity: Number(item.POITEMQTY),
        Rate:
          Number(item.POITEMAMOUNT) +
          Number(item.POVAT) / Number(item.POITEMQTY),
        TotalAmount:
          (Number(item.POITEMAMOUNT) +
            Number(item.POVAT) / Number(item.POITEMQTY)) *
          Number(item.POITEMQTY),
        Vatable: `${item.POVATABLE === 0 ? 0 : 1}`,
        UOM: item.UOM,
        FixedTotal:
          (Number(item.POITEMAMOUNT) + Number(item.POVAT)) *
          (Number(item.POITEMQTY) - Number(item.POITEMREMQTY)),
        Rateexc: Number(item.POITEMAMOUNT),
        PoQty: Number(item.POITEMQTY),
      };
    });
    setItemsInGrid(updatedArray);
  }
  // fetchParticularGrn;
  function getPoHeaders(newPOno) {
    if (newPOno) {
      setHist(true);
      setPdfData(null);
      axios
        .get(
          `${BASEURL_GLOBAL}/globalViewHandler?viewname=617&OT_GRN_NO=${newPOno}`,
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          // setSelectedRow(response.data[0]);
          setGrnNO(response.data[0].OT_GRN_NO[0]);
          setGrnRefDoc(response.data[0].PONO);
          setSupplierCode(response.data[0].OT_GRN_SUPPLIER_CODE);
          setSupplierName(response.data[0].SUPPLIERNAME);
          setSupplierCurrency(response.data[0].OT_GRN_SUPPLIER_CURRENCY);
          setGrnNotes(response.data[0].OT_GRN_COMMENTS);
          setSupplierInvoice(response.data[0].OT_GRN_SUPPLIER_INVOICE);
          setSupplierInvoiceDate(response.data[0].OT_GRN_SUPPLIER_INVOICE_DATE);
          setGrandTotal(response.data[0].OT_GRN_TOTAL_COST);
          setSelReceivedBy(response.data[0].OT_GRN_RECIEVED_BY);
          setSelGrnLocation(response.data[0].OT_GRN_LOCATION);
          setDate(response.data[0].OT_GRN_DATE);
          // setgetGrnno(response.data[0].OT_GRN_NO);
          // SetCurrentGrnNumber(response.data);
          //   const supplierItem = supplierList.find(
          //     (item) => item?.VENDORCODE === response.data[0].OT_GRN_SUPPLIER_CODE
          //   );

          //   setSupplierName(supplierItem?.VENDNAME);
        })
        .catch((error) => {
          console.error("Error fetching List Of Credit Sale History:", error);
          // setisloading(false);
        });

      // getGrnItemDetails;
      axios
        .get(
          `${BASEURL_GLOBAL}/globalViewHandler?viewname=618&OT_GRN_NO=${newPOno}`,
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          const sortedData = response?.data?.sort(
            (a, b) => a?.SERIALNO - b?.SERIALNO
          );
          setViewDatatoTable(sortedData);
        })
        .catch((error) => {
          console.error("Error fetching List Of Credit Sale History:", error);
          // setisloading(false);
        });
    }
  }
  // getGrnheaderdetails;
  function getPurchaseOrderDetails() {
    setIsLoading(true);
    axios
      .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=617`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setgetGrndetails(response.data);
        SetStartPoNum(response.data[0].OT_GRN_NO);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Error while fetching Purchase Order Details", err);
      });
  }

  const filtHistoricCash = getGrndetails.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const sDate = item.OT_GRN_DATE;
    const eDate = item.OT_GRN_DATE;

    if (searchData.length === 0 && !startdate && !enddate) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return ["OT_GRN_DATE", "OT_GRN_SUPPLIER_CODE", "OT_GRN_NO"].some(
        (key) => {
          const columnValue = String(item[key]).toLowerCase();
          return columnValue.includes(term);
        }
      );
    });
    const dateMatch =
      (!startdate || sDate >= startdate) && (!enddate || eDate <= enddate);

    return textMatch && dateMatch;
  });

  const handleButtonClick = (action) => {
    let newPOno;
    setHist(true);
    setPdfData(null);
    switch (action) {
      case "first":
        newPOno = StartPoNum;
        break;
      case "decrement":
        newPOno = Math.max(StartPoNum, GrnNo - 1);

        break;
      case "increment":
        newPOno = Math.min(Number(GrnNo) + 1, currentPonum);

        break;
      case "latest":
        newPOno = Number(currentPonum) - 1;

        break;

      default:
        break;
    }

    setGrnNO(newPOno); // Update state immediately
    getPoHeaders(newPOno); // Pass newPOno as an argument
  };

  const handleCreditSaleClick = (row) => {
    // setShowTable(true);
    // setCashSaleHistoric(false);
    setSelectedRow(row);
    setgetGrnno(row.PONO);
    getPoHeaders(row.PONO);
    setcashsalepopup(false);
  };

  const filtHistoricCashItem = getItemDetails.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    if (searchData.length === 0 && !startdate && !enddate) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return [
        "ITEM CODE",
        "ITEM DESCRIPTION",
        "LATEST COST PRICE",
        "ITEM BASE UOM",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    return textMatch;
  });
  const handleItemmaptoinput = (row) => {
    setItemCode(row["ITEM CODE"]);
    setItemName(row["ITEM DESCRIPTION"]);
    setRate(
      row["ITEM VATABLE"] == 1
        ? Number(1.18) * Number(row["LATEST COST PRICE"])
        : row["LATEST COST PRICE"]
    );
    setUom(row["ITEM BASE UOM"]);
    // setVat(row["ITEM VATABLE"]);
    setItempopup(false);
  };
  const handleQuantityChange = (event) => {
    setQuantity(parseInt(event.target.value, 10)); // Parse the value to an integer
  };

  const AddItemsToGrid = () => {
    if (!itemName) {
      alert("Please select Item first");
    } else if (Number(quantity) <= 0) {
      alert("Quantity can not be empty, negative, or 0");
      setQuantity("");
    } else {
      const newItem = {
        ItemCode: itemCode,
        ItemName: itemName,
        Quantity: quantity,
        Rate: rate,
        TotalAmount: Number(quantity) * Number(rate),
        UOM: uom,
      };
      const existingRowIndex = itemsInGrid.findIndex(
        (charge) =>
          charge.ItemCode === newItem.ItemCode &&
          charge.ItemName === newItem.ItemName
      );

      if (existingRowIndex !== -1) {
        // Row already exists, update it
        const updatedItems = [...itemsInGrid];
        updatedItems[existingRowIndex] = newItem;
        setItemsInGrid(updatedItems);
      } else {
        // Row doesn't exist, add it as a new one
        setItemsInGrid([...itemsInGrid, newItem]);
      }

      setShowTable(true);
      clearallinputs();
    }
  };

  const clearallinputs = () => {
    setSelectedRowIndex(null);
    setItemCode("");
    setItemName("");
    setQuantity("");
    setRate("");
    setUom("");
  };

  const fetchBackItems = (value, index) => {
    setItemName(value.ItemName ? value.ItemName : "");
    setItemCode(value.ItemCode ? value.ItemCode : "");
    setQuantity(value.Quantity ? value.Quantity : "");
    setRate(value.Rate ? value.Rate : "");
    setUom(value.UOM ? value.UOM : "");
    setSelectedRowIndex(index);
  };

  const RemoveItems = () => {
    const updatedValues = [...itemsInGrid];
    if (updatedValues.length === 0) {
      alert("There is no Items to be removed");
      setShowTable(false);
    } else {
      if (
        selectedRowIndex !== null &&
        selectedRowIndex >= 0 &&
        selectedRowIndex < updatedValues.length
      ) {
        updatedValues.splice(selectedRowIndex, 1);
        setSelectedRowIndex(null);
      } else {
        updatedValues.pop();
      }
      setItemsInGrid(updatedValues);
      clearallinputs();
      if (updatedValues.length === 0) {
        setShowTable(false);
      }
    }
  };

  const handleSupplierCode = (e) => {
    const selectedValue = e;
    const selectedOption = supplierList.find(
      (obj) => obj.VENDORCODE === selectedValue
    );
    if (selectedValue === "") {
      setSupplierName("");
      setSupplierCode("");
    } else if (selectedOption === "") {
      setSupplierName("");
      setSupplierCode("");
    } else {
      setSupplierCode(selectedValue);
      setSupplierName(selectedOption.VENDNAME);
    }
  };

  const handleSupplierName = (e) => {
    const selectedValue = e;
    const selectedOption = supplierList.find(
      (obj) => obj.VENDNAME === selectedValue
    );
    if (selectedValue === "") {
      setSupplierName("");
      setSupplierCode("");
    } else if (selectedOption === "") {
      setSupplierName("");
      setSupplierCode("");
    } else {
      setSupplierName(selectedValue);
      setSupplierCode(selectedOption.VENDORCODE);
    }
  };

  // ********************************** SAVE ******************************************

  function poObjects() {
    setItemsInGrid(poData?.poDetails);
    const headerObject = poData?.poHeader;
    setGrnRefDoc(headerObject.PONO);
    setSupplierCode(headerObject.SUPPLIERCODE);
    setSupplierName(headerObject.SUPPLIERNAME);
    setSupplierCurrency(headerObject.SUPPLIERCURRENCY);
  }
  // insertGrnHeader;
  async function SaveGrnHeader() {
    try {
      const response = await axios.post(
        `${BASEURL_GLOBAL}/globalSpHandler?spname=144&successstatus=true`,
        {
          GRNNO: Number(GrnNo),
          GRNDATE: moment(date).format("YYYY/MM/DD"),
          GRNREFDOC: Number(grnRefDoc),
          SUPPLIERCODE: supplierCode,
          SUPPLIERCURRENCY: supplierCurrency,
          SUPPLIEREXCHANGERATE: Number(supplierExchRate),
          SUPPLIERINVOICE: supplierInvoice,
          SUPPLIERINVOICEDATE: moment(supplierInvoiceDate).format("YYYY/MM/DD"),
          GRNLOCATION: selGrnLocation,
          GRNNOTES: grnNotes,
          GRNRECEIVEDBY: selReceivedBy,
          GRNTOTALCOST: Number(grandTotal),
          CREATEDBY: userName,
          CREATEDDATE: moment(date).format("YYYY/MM/DD"),
          CREATEDTIME: "",
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );

      if (response.status === 201) {
        alert(response.data.message);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error(`Error while saving GRN Header`, error);
      alert(error);
    }
  }
  // insertGrnItems;
  async function SaveGrnDetails() {
    setIsLoading(true);
    let successCount = 0;
    let successMessage = null;
    for (let i = 0; i < itemsInGrid.length; i++) {
      const value = itemsInGrid[i];

      try {
        const response = await axios.post(
          `${BASEURL_GLOBAL}/globalSpHandler?spname=145&successstatus=true`,
          {
            GRNNO: Number(GrnNo),
            GRNREFNO: Number(grnRefDoc),
            ITEMINDEX: Number(i + 1),
            ITEMCODE: value.ItemCode,
            ITEMDESCRIPTION: value.ItemName,
            LOCATION: selGrnLocation,
            ITEMQTY: Number(value.Quantity),
            ITEMRATEINCLUSIVE: Number(value.Rate),
            ITEMUOM: value.UOM,
            CREATEDBY: userName,
            CREATEDDATE: date,
            ITEMRATEEXCLUSIVE: value.Rateexc,
            GRNDATE: moment(date).format("YYYY/MM/DD"),
            DIVISION: divsionInitial,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );

        if (response.status === 201) {
          successCount++;
          successMessage = response.data.message;
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        setIsLoading(false);
        console.error(`Error while saving row `, error);
        alert(error.response.data.message);
      }
    }
    setIsLoading(false);
    if (successCount == itemsInGrid.length && successMessage) {
      alert(successMessage);
    }
  }

  // Crystal Reports
  function generateReport() {
    setPdfData(null);

    if (!GrnNo) {
      alert("Please select a GRN No");
      return;
    }
    setLoadingScreenBlock(true);
    axios
      .post(
        `${BASEURL_REPORTS}/getReports`,
        {
          REPORTNAME: reportName,
          DOCNO: Number(GrnNo),
          REPORTPATH: `${reportPath?.replace(/\\\\/g, "\\")}`,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
          responseType: "blob",
        }
      )
      .then((res) => {
        console.log(res);
        const file = new Blob([res.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        setPdfData(fileURL);
        setLoadingScreenBlock(false);
      })
      .catch((err) => {
        console.log("Error generating report:", err);
        setLoadingScreenBlock(false);
      });
  }

  const handleDownload = () => {
    const a = document.createElement("a");
    a.href = pdfData;
    a.download = "generated_report.pdf";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  if (isLoading) {
    return <Loading />;
  }
  return (
    <div className="text-ls">
      <div className="toHIde__when__Print">
        <AllPageNavbar />

        <header className="header__of__main lg:flex  justify-between items-end px-3 ">
          <h1 className="header__of__page"> Goods Recieved Note</h1>
          <main className="flex justify-end p-2 text-right gap-[19px]">
            <p className="font-bold">
              DIVISION : <span className="text-red-600">{divsionInitial}</span>
            </p>
            <p className="font-bold">
              PROCESSED BY :{" "}
              <span className="text-red-600"> {LoggedInUserName}</span>
            </p>
          </main>
        </header>

        <section className="block justify-between lg:lg:flex ">
          <div className="flex felx-row mt-2 ml-[10px]">
            <button
              className="  border border-black bg-[#002d62] text-white rounded-md px-3   mr-1 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
              onClick={() => {
                handleButtonClick("first");
              }}
            >
              {"<<"}
            </button>
            <button
              className="  border border-black bg-[#002d62] text-white rounded-md px-3   mr-1 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
              onClick={() => {
                handleButtonClick("decrement");
              }}
            >
              {"<"}
            </button>
            <button
              className="  border border-black bg-[#002d62] text-white rounded-md px-3  mr-1 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
              onClick={() => {
                handleButtonClick("increment");
              }}
            >
              {">"}
            </button>
            <button
              className="  border border-black bg-[#002d62] text-white rounded-md px-3  mr-1 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
              onClick={() => {
                handleButtonClick("latest");
              }}
            >
              {">>"}
            </button>
          </div>
          <div className="flex felx-row mt-2 ml-[10px]">
            <button
              className="  border border-black bg-[#002d62] text-white rounded-md px-3   mr-1 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
              role="button"
              onClick={() => {
                dispatch({ type: "RESET_POTOGRN" });
                window.location.reload();
              }}
            >
              New
            </button>
            <button
              className="  border border-black bg-[#002d62] text-white rounded-md px-3  mr-1 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
              onClick={async () => {
                if (
                  (itemsInGrid.length > 0 &&
                    GrnNo &&
                    grnRefDoc &&
                    selGrnLocation,
                  date &&
                    supplierInvoice &&
                    supplierInvoiceDate &&
                    selReceivedBy)
                ) {
                  await SaveGrnHeader();
                  await SaveGrnDetails();
                  window.location.reload();
                } else {
                  alert("Enter all details");
                }
              }}
            >
              Save
            </button>

            <button
              className="  border border-black bg-[#002d62] text-white rounded-md px-3   mr-1 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
              role="button"
              onClick={() => {
                window.print();
              }}
            >
              Print
            </button>
            <button
              className="  border border-black bg-[#002d62] text-white rounded-md px-3   mr-1 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
              role="button"
              onClick={() => {
                if (!grnRefDoc) {
                  alert("No PO opened");
                } else {
                  handleGrnToPo(grnRefDoc);
                }
              }}
            >
              Go to PO
            </button>

            <button
              className="bg-blue-900 text-white  h-7 text-lg rounded-md px-3 ml-2 mr-2 focus:outline-none focus:ring"
              onClick={() => {
                if (hist === false) {
                  alert("Kindly Save the Grn first");
                } else {
                  generateReport();
                }
              }}
            >
              Generate Report
            </button>

            {/* <button
            className="  border border-black bg-[#002d62] text-white rounded-md px-3 py-1  mr-1 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
            role="button"
          >
            GRN Costing
          </button> */}
            {/* <button
            className="  border border-black bg-[#002d62] text-white rounded-md px-3 py-1  mr-1 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
            role="button"
          >
            Print...
          </button> */}
          </div>
        </section>

        <main className="block bg-slate-200 p-2 mt-2 shadow mb-2 mx-2 rounded">
          <aside className="lg:flex  mt-2 gap-6 ">
            <div className="lg:flex   mt-1 ">
              <p className="  lg:w-[120px]">GRN NO</p>

              <input
                className="w-24 h-8 border border-black  p-2 rounded-md focus:outline-none focus:border-blue-500"
                value={GrnNo ? GrnNo : ""}
              ></input>

              <button
                onClick={() => {
                  setcashsalepopup(true);
                }}
                className="flex items-center justify-center border border-black bg-[#002d62] h-8 text-white rounded-md p-2 ml-2 mr-2 focus:outline-none focus:ring focus:border-blue-300"
              >
                ...
              </button>
            </div>
            {cashsalepopup && (
              <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 bg-white border border-black h-[500px] w-[900px] p-1 overflow-y-auto">
                <div className="flex flex-row mt-2">
                  <p className="text-red-600 ml-2">Search:</p>
                  <input
                    className="w-64 h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                    type="text"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  ></input>
                  <p className="text-red-600 ml-2">Start Date:</p>
                  <input
                    type="date"
                    className="w-36 h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                    onChange={(e) => {
                      setstartdate(e.target.value);
                    }}
                    value={startdate.split("T")[0]}
                  ></input>
                  <p className="text-red-600 ml-2">End Date:</p>
                  <input
                    type="date"
                    className="w-36 h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                    onChange={(e) => {
                      setEnddate(e.target.value);
                    }}
                    value={enddate.split("T")[0]}
                  ></input>
                </div>

                <button
                  className="absolute top-2 right-2 flex items-center justify-center border border-black bg-[#002d62] text-white rounded-md px-3 py-1 ml-2 mr-2    focus:outline-none focus:ring focus:border-blue-300"
                  onClick={() => setcashsalepopup(false)}
                >
                  Close
                </button>
                <aside className="table__pos__app ">
                  <table class="custom-table">
                    {/* <thead className="bg-blue-900 border-t-2 border-blue-800 rounded-md text-white font-semibold h-8 sticky top-0 w-98 z-0"> */}
                    <thead class="header-cell sticky top-0 w-98 z-0 text-sm">
                      <tr>
                        <td className="text-right ">GRN NO</td>
                        <td className="text-left">GRN DATE</td>
                        <td className="text-left">SUPPLIER NAME</td>
                        {/* <td className="text-left">Customer Name</td>
                      <td className="text-right">Financial Year</td>
                      <td className="text-right">Amount</td> */}
                      </tr>
                    </thead>
                    <tbody>
                      {filtHistoricCash.map((row, index) => (
                        <tr
                          className="cursor-pointer"
                          key={row?.PONO}
                          onClick={() => {
                            setcashsalepopup(false);
                            // handleCreditSaleClick(row);
                            getPoHeaders(row?.OT_GRN_NO);
                          }}
                        >
                          <td className="text-right pr-6">
                            {row?.OT_GRN_NO ? row?.OT_GRN_NO : ""}
                          </td>
                          <td>
                            {row.OT_GRN_DATE
                              ? moment(row?.OT_GRN_DATE?.split("T")[0]).format(
                                  "DD/MM/YYYY"
                                )
                              : ""}
                          </td>
                          <td>
                            {row?.["SUPPLIERNAME"] ? row?.["SUPPLIERNAME"] : ""}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </aside>
              </div>
            )}

            <div className="lg:flex    mt-1">
              <p>PO NUMBER</p>
              <p>
                <input
                  value={grnRefDoc ? grnRefDoc : 0}
                  // onChange={(e) => {
                  //   setGrnRefDoc(e.target.value);
                  // }}
                  disabled
                  className="w-24 h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                ></input>
              </p>
            </div>

            <div className="lg:flex    mt-1">
              <p>
                <p>GRN Location </p>
              </p>
              <Select
                styles={customStyless}
                onChange={(e) => {
                  setSelGrnLocation(e.value);
                }}
                value={
                  selGrnLocation
                    ? { label: selGrnLocation, value: selGrnLocation }
                    : { label: "", value: "" }
                }
                options={[
                  { label: " ", value: "" },
                  ...location.map((item) => ({
                    label: item.OM_LOC_CODE,
                    value: item.OM_LOC_CODE,
                  })),
                ]}
              />
            </div>
            <div className="lg:flex    mt-1">
              <p className="w-[86px] text-right lg:ml-[54px]">GRN Date</p>

              <p>
                <input
                  value={date.split("T")[0]}
                  type="date"
                  className="w-38  h-8 ml-2 rounded-md"
                ></input>
              </p>
            </div>
          </aside>
          <aside className=" lg:flex  grn_section gap-4">
            <div className="lg:flex    mt-1">
              <p className="lg:w-[120px]">Supplier Name </p>
              <Select
                styles={customStyless}
                // onChange={(e) => {
                //   if (e.value == "") {
                //     alert("Kindly Supply Proper Supplier Name");
                //   } else {
                //     handleSupplierName(e.value);
                //   }
                // }}
                disabled
                value={
                  supplierName
                    ? { label: supplierName, value: supplierName }
                    : { label: "", value: "" }
                }
                // options={[
                //   { label: " ", value: "" },
                //   ...supplierList.map((item) => ({
                //     label: item.VENDNAME,
                //     value: item.VENDNAME,
                //   })),
                // ]}
              />
            </div>
            {/* <div className="lg:flex    mt-1">
            <p>Supplier Code </p>
            <Select
              styles={customStyles1}
              // onChange={(e) => {
              //   if (e.value == "") {
              //     alert("Kindly Supply Proper Supplier Code");
              //   } else {
              //     handleSupplierCode(e.value);
              //   }
              // }}
              disabled
              value={
                supplierCode
                  ? { label: supplierCode, value: supplierCode }
                  : { label: "", value: "" }
              }
              // options={[
              //   { label: " ", value: "" },
              //   ...supplierList.map((item) => ({
              //     label: item.VENDORCODE,
              //     value: item.VENDORCODE,
              //   })),
              // ]}
            />
          </div> */}

            <div className="lg:flex    mt-1">
              <p className="lg:ml-[30px]">Supplier Currency </p>
              <p>
                <input
                  value={supplierCurrency}
                  // onChange={(e) => {
                  //   setSupplierCurrency(e.target.value);
                  // }}
                  disabled
                  className=" h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                ></input>
              </p>
            </div>

            <div className="lg:flex   mt-1">
              <p className="lg:ml-[124px]">Supplier Exch Rate </p>
              <p>
                <input
                  value={supplierExchRate}
                  className="w-28 h-8 ml-1"
                ></input>
              </p>
            </div>
          </aside>
          <aside className=" lg:flex  grn_section gap-4">
            <div className="lg:flex">
              <p className="  ">
                <p className="lg:w-[120px]">Supplier Invoice</p>
              </p>
              <p>
                <input
                  value={supplierInvoice}
                  onChange={(e) => {
                    setSupplierInvoice(e.target.value);
                  }}
                  className=" h-8 "
                ></input>
              </p>
            </div>

            <div className="lg:flex">
              <p className=" lg:text-right mr-2 lg:ml-[182px]">
                <p>Received By </p>
              </p>
              <Select
                styles={customStyless}
                onChange={(e) => {
                  setSelReceivedBy(e.value);
                }}
                value={
                  selReceivedBy
                    ? { label: selReceivedBy, value: selReceivedBy }
                    : { label: "", value: "" }
                }
                options={[
                  { label: " ", value: "" },
                  ...receivedby.map((item) => ({
                    label: item["EMPLOYEE NAME"],
                    value: item["EMPLOYEE NAME"],
                  })),
                ]}
              />
            </div>
            <div className="lg:flex">
              <p className="  lg:text-right mr-2">
                <p>Supplier Invoice Date</p>
              </p>
              <p>
                <input
                  value={supplierInvoiceDate?.split("T")[0]}
                  type="date"
                  onChange={(e) => {
                    setSupplierInvoiceDate(e.target.value);
                  }}
                  className=" h-8 "
                ></input>
              </p>
            </div>
          </aside>
          <aside>
            <div className="lg:flex">
              <p className="  my-1">
                <p className="w-[120px] ">Grn Notes </p>
              </p>
              <p>
                <textarea
                  value={grnNotes}
                  onChange={(e) => {
                    setGrnNotes(e.target.value);
                  }}
                  className="w-[780px] my-1 border border-black p-2 rounded-md focus:outline-none focus:border-blue-500 h-14"
                ></textarea>
              </p>
              {/* <div className="ml-[20px] mt-[5px]">
                <p>
                  {" "}
                  <span className="w-[100px] inline-block">Division:</span>{" "}
                  {division ? division : ""}
                </p>
                <p>
                  <span className="w-[100px] inline-block">Processed By:</span>{" "}
                  {userName ? userName : ""}
                </p>
              </div> */}
            </div>
          </aside>
        </main>
        {/* *************************************** Details Section *************************************** */}
        {/* <main className="lg:block">
        <div className="flex flex-row mt-2 ml-2 gap-2">
          <p className="text-red-600 mr-2 w-[120px] lg:w-[auto]">
            Item code :{" "}
          </p>
          <input value={itemCode} className="w-[100px]" readOnly></input>
          <button
            className="flex items-center justify-center border border-black bg-[#002d62]  text-white rounded-md px-3 ml-2 mr-2    focus:outline-none focus:ring focus:border-blue-300"
            onClick={() => {
              setItempopup(true);
            }}
          >
            Search
          </button>
          {itempopup && (
            <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 bg-white border border-black h-[500px] w-[900px] p-1 overflow-y-auto">
              <div className="flex flex-row mt-2">
                <p className="text-red-600 ml-2">Search:</p>
                <input
                  className="w-64 h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                  type="text"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                ></input>
              </div>

              <button
                className="absolute top-2 right-2 flex items-center justify-center border border-black bg-[#002d62] text-white rounded-md px-3 py-1 ml-2 mr-2    focus:outline-none focus:ring focus:border-blue-300"
                onClick={() => setItempopup(false)}
              >
                Close
              </button>
              <aside className="table__pos__app ">
                <table class="custom-table">
                  <thead class="header-cell sticky top-0 w-98 z-0 text-sm">
                    <tr>
                      <td className="text-right ">ITEM CODE</td>
                      <td className="text-left">ITEM NAME</td>
                      <td className="text-left">RATE</td>
                      <td className="text-left">UOM</td>
                    </tr>
                  </thead>
                  <tbody>
                    {filtHistoricCashItem.map((row, index) => (
                      <tr
                        key={row.PONO}
                        onClick={() => {
                          setcashsalepopup(false);
                          handleItemmaptoinput(row);
                        }}
                        className={
                          index < getItemDetails.length
                            ? "border-b cursor-pointer text-sm table-row"
                            : ""
                        }
                      >
                        <td className="text-right pr-6">
                          {row["ITEM CODE"] ? row["ITEM CODE"] : ""}
                        </td>
                        <td>
                          {row["ITEM DESCRIPTION"]
                            ? row["ITEM DESCRIPTION"]
                            : ""}
                        </td>
                        <td>
                          {row["LATEST COST PRICE"]
                            ? row["LATEST COST PRICE"].toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : "N/A"}
                        </td>
                        <td>
                          {row["ITEM BASE UOM"] ? row["ITEM BASE UOM"] : ""}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </aside>
            </div>
          )}

          <p className="block lg:flex">
            <p className="text-red-600 mr-1">Item Name</p>
            <input
              className=" w-[280px] h-8 border border-black ml-1 p-2 rounded-md focus:outline-none focus:border-blue-500"
              value={itemName}
              readOnly
            ></input>
          </p>
          <p className="block lg:flex">
            <p className="text-red-600 mr-1 w-[56px] text-right"> Qty</p>
            <input
              type="number"
              className="w-36 h-8 border border-black ml-1 p-2 rounded-md focus:outline-none focus:border-blue-500"
              value={quantity}
              onChange={handleQuantityChange}
            ></input>
          </p>
        </div>
        <div className="lg:flex  block flex-row mt-2 ml-2 gap-2">
          <p className="block lg:flex">
            <p className="text-red-600 mr-1 w-[72px] text-right">UOM</p>
            <input
              className="w-[190px]  h-8 border border-black ml-1 p-2 rounded-md focus:outline-none focus:border-blue-500"
              value={uom}
              readOnly
            ></input>
          </p>
          <p className="block lg:flex">
            <p className="text-red-600 mr-1 w-[140px] text-right">Rate</p>
            <input
              className=" h-8 border border-black ml-1 p-2 rounded-md focus:outline-none focus:border-blue-500"
              value={
                rate
                  ? rate.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : ""
              }
              readOnly
            ></input>
          </p>
          <p>
            <button
              className="border bg-cyan-300 px-1 text-ls rounded-md"
              onClick={() => {
                AddItemsToGrid();
              }}
            >
              ADD
            </button>
          </p>
          <p>
            <button
              className="border bg-cyan-300 px-1 text-ls rounded-md"
              onClick={() => {
                RemoveItems();
              }}
            >
              REMOVE
            </button>
          </p>
        </div>
      </main> */}

        <div className="w-full h-auto p-1">
          {ShowTable && (
            <aside className="table__pos__app text-[16px] w-[98%] ">
              <table class="custom-table ">
                <thead class="header-cell">
                  <tr>
                    <td className="text-left">Item Code</td>
                    <td className="text-left">Item Name</td>
                    <td className="text-left">PO QTY</td>
                    <td className="text-left">Quantity Pending</td>
                    <td className="text-right">Quantity To GRN</td>
                    <td className="text-right">Rate</td>
                    <td className="text-right">Total Amount</td>
                    <td className="text-left">UOM</td>
                  </tr>
                </thead>
                <tbody>
                  {historic.length > 0
                    ? historic.map((value, index) => (
                        <tr
                          className={
                            index < historic.length
                              ? "border-b cursor-pointer table-row text-sm"
                              : ""
                          }
                          onClick={() => {
                            fetchBackItems(value);
                          }}
                        >
                          <td>
                            {value["ITEM CODE"] ? value["ITEM CODE"] : ""}
                          </td>
                          <td style={{ maxWidth: "250px" }}>
                            {value["ITEM NAME"] ? value["ITEM NAME"] : ""}
                          </td>
                          <td className="text-right">
                            {value.QTY ? value.QTY : ""}
                          </td>
                          <td className="text-right">
                            {value["ITEM VALUE EXC VAT"]
                              ? value["ITEM VALUE EXC VAT"].toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              : 0.0}
                          </td>
                          <td className="text-right">
                            {value.VATAMOUNT
                              ? value.VATAMOUNT.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0.0}
                          </td>
                          <td className="text-right">
                            {value.LEVYAMOUNT
                              ? value.LEVYAMOUNT.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0.0}
                          </td>

                          <td className="text-right">
                            {value["ITEM VALUE"]
                              ? value["ITEM VALUE"].toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0.0}
                          </td>
                          <td className="text-right">
                            {value.QTY
                              ? (
                                  Number(value.QTY) *
                                  Number(value["ITEM VALUE"])
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0.0}
                          </td>
                          <td className="text-right">
                            {value["ITEM DISC"]
                              ? value["ITEM DISC"].toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0.0}
                          </td>
                          <td className="text-left pl-4">
                            {value.OT_INV_ITEM_ASSOCIATED == 1 ? "YES" : "NO"}
                          </td>
                        </tr>
                      ))
                    : itemsInGrid.map((value, index) => {
                        // const fixedQty = value.Quantity;

                        // const totalamt =
                        //   Number(value.FixedQty) * Number(value.Rate);
                        // const taxPercentage = (
                        //   ((Number(value.FixedTotal) - totalamt) / totalamt) *
                        //   100
                        // ).toFixed(2);

                        // value.RatewithVat = value.Vatable
                        //   ? value.Rate *
                        //     Number(
                        //       `1.${taxPercentage.toString().split(".")[0]}`
                        //     )
                        //   : value.Rate;
                        return (
                          <tr
                            className={
                              index < itemsInGrid.length - 1
                                ? "border-b cursor-pointer text-sm"
                                : "border-b cursor-pointer text-sm "
                            }
                            // onClick={() => {
                            //   fetchBackItems(value, index);
                            // }}
                          >
                            <td>{value.ItemCode ? value.ItemCode : ""}</td>
                            <td style={{ maxWidth: "250px" }}>
                              {value.ItemName ? value.ItemName : ""}
                            </td>
                            <td
                              className="text-right"
                              style={{ maxWidth: "250px" }}
                            >
                              {value.PoQty ? value.PoQty : ""}
                            </td>
                            <td
                              className="text-right"
                              style={{ maxWidth: "250px" }}
                            >
                              {value.FixedQty ? value.FixedQty : 0}
                            </td>
                            <td className="text-right">
                              <input
                                className="text-right"
                                type="number"
                                defaultValue={
                                  value?.Quantity ? value?.Quantity : 0
                                }
                                onChange={(e) => {
                                  if (e.target.value <= value.FixedQty) {
                                    handleQuantityChange1(
                                      index,
                                      e.target.value
                                    );
                                  } else {
                                    e.target.value = value.Quantity;
                                    alert(
                                      "Quantity cannot be greater than quantity pending"
                                    );
                                  }
                                }}
                              />
                            </td>
                            <td className="text-right">
                              {value.Rate
                                ? value.Rate.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : 0.0}
                            </td>
                            <td className="text-right">
                              <input
                                className="text-right"
                                value={
                                  value.TotalAmount
                                    ? value.TotalAmount.toLocaleString(
                                        undefined,
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }
                                      )
                                    : 0.0
                                }
                                disabled
                              />
                              {/* {value.TotalAmount
                              ? value.TotalAmount.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0.0} */}
                            </td>

                            <td className="text-left pl-4">
                              {value.UOM ? value.UOM : ""}
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </table>
            </aside>
          )}
          <div className="lg:flex gap-1 font-semibold lg:ml-2 justify-end lg:mx-[50px]">
            <p>Grand Total :</p>
            <p className="text-red-600">
              {grandTotal
                ? grandTotal.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : 0}
            </p>
          </div>
        </div>
      </div>

      {/* Report Generation */}
      {LoadingScreenBlock ? (
        <LoadingSpinner />
      ) : (
        <>
          {pdfData ? (
            <div className="w-full justify-center items-center min-h-screen p-4">
              <section className="lg:flex lg:flex-col bg-white p-4 ga  shadow-lg rounded-md w-full min-h-[500px]">
                <div>
                  {/* <div className="flex items-center gap-4"></div> */}

                  <section className="lg:hidden block">
                    {pdfData && (
                      <button
                        className="bg-blue-900 text-white  h-7 text-lg rounded-md px-3 ml-2 mr-2 focus:outline-none focus:ring"
                        onClick={handleDownload}
                      >
                        Download PDF
                      </button>
                    )}
                  </section>

                  <section className="hidden lg:block">
                    {pdfData ? (
                      <div className="mt-4">
                        <h2 className="text-lg font-bold">Generated Report</h2>
                        <iframe
                          src={pdfData}
                          title="Generated Report"
                          width="100%"
                          height="600px"
                        ></iframe>
                      </div>
                    ) : (
                      <p className="text-2xl font-bold my-4 text-center">
                        No report available.
                      </p>
                    )}
                  </section>
                </div>
              </section>
            </div>
          ) : (
            ""
          )}
        </>
      )}

      <section className="toSHow__when__Print">
        <main className="relative my-2  mx-[50px] w-[705px] h-[942px] bg-slate-50 p-[10px] text-[10px]">
          <section className="border-[1.5px] border-blue-900 rounded-[10px] my-3 w-[100%] p-[10px]">
            <header>
              <h1 className="text-[25px] text-red-900 font-bold">
                {companyLOGO}
              </h1>
            </header>
            <section>
              <h2 className="text-center font-bold text-[16px] underline">
                GOODS RECEIPT NOTE
              </h2>
            </section>
            <section className="feilds_top__goods">
              <h1>GRN NO</h1>
              <p>{GrnNo ? GrnNo : ""}</p>
              <h1>PO NO</h1>
              <p>{grnRefDoc ? grnRefDoc : ""}</p>
            </section>
            <section className="feilds_top__goods">
              <h1>SUPPLIER NAME</h1>
              <p> {supplierName ? supplierName : ""}</p>
              <h1>DATE</h1>
              <p>{date ? moment(date).format("DD/MM/YYYY") : ""}</p>
              <h1>GRN DATE </h1>
              <p> {date ? moment(date).format("DD/MM/YYYY") : ""}</p>
              <h1>DIVISION </h1>
              <p>{divsionInitial}</p>
              <h1>INVOICE</h1>
              <p>{supplierInvoice ? supplierInvoice : ""}</p>
              <h1>LOCATION </h1>
              <p>{selGrnLocation ? selGrnLocation : ""}</p>
              <h1>INVOICE DATE </h1>
              <p>
                {supplierInvoiceDate
                  ? moment(supplierInvoiceDate).format("DD/MM/YYYY")
                  : ""}
              </p>
              <h1>CREATED BY </h1>
              <p>{LoggedInUserName}</p>
            </section>
            <table class="table-print">
              <thead>
                <tr>
                  <th>SNo</th>

                  <th>ITEM DESCRIPTION</th>
                  <th>TOTAL QTY</th>

                  <th>UOM </th>

                  <th>GRN RATE </th>
                  <th>AMOUNT </th>
                </tr>
              </thead>
              <tbody>
                {itemsInGrid?.map((item, index) => (
                  <tr key={index} className="table-row">
                    <td className="text-right">{index + 1}</td>{" "}
                    <td>{item?.ItemName}</td>
                    <td className="text-right">
                      {item?.Quantity.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                      })}
                    </td>
                    <td className="text-left">{item?.UOM ? item?.UOM : ""}</td>
                    <td className="text-right">
                      {item?.Rate.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </td>
                    <td className="text-right">
                      {item?.TotalAmount.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {itemsInGrid?.length > 0 && (
              <div className="flex justify-between mx-1 text-[14px] my-2">
                <h2>
                  TOTAL GRN QUANTITY : &nbsp;
                  {itemsInGrid
                    .reduce((total, item) => total + (item.Quantity || 0), 0)
                    .toLocaleString(undefined, {
                      minimumFractionDigits: 0,
                    })}
                </h2>
                <h2>
                  <span>
                    TOTAL AMOUNT: &nbsp;
                    {itemsInGrid
                      .reduce(
                        (total, item) => total + (item.TotalAmount || 0),
                        0
                      )
                      .toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                  </span>
                </h2>
              </div>
            )}
          </section>
          <div className="bottom__sign">
            <h3 className="">Recived BY :</h3>
            <p>________</p>
            <h3 className="">Sign :</h3>
            <p>________</p>
            <h3 className=""> Date :</h3>
            <p>________</p>
            <h3 className="">Time :</h3>
            <p>________</p>
          </div>
          <div className="bottom__sign">
            <h3 className="">Checked BY :</h3>
            <p>________</p>
            <h3 className="">Sign :</h3>
            <p>________</p>
            <h3 className=""> Date :</h3>
            <p>________</p>
            <h3 className="">Time :</h3>
            <p>________</p>
          </div>
          <section className="text-center my-[100px]">
            <h1>Powered by Genius Business System</h1>
            <h1>Marstrack ERP</h1>
            <h1>www.gbsafrica.net</h1>
          </section>
        </main>
      </section>
    </div>
  );
}

export default Grn;
